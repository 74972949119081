<template>
  <div>
  <v-dialog v-model="display" persistent width="500">
    <v-card flat>
        <v-card-title>{{ title }}</v-card-title>
        <div class="text-center" v-if="loading.data">
            <v-progress-circular
                indeterminate>
            </v-progress-circular>
        </div>
        <v-card-text v-else>
            <v-form v-model="value">
                <v-text-field 
                    class="mt-2" 
                    :label="$t('name')" 
                    v-model="form.username"
                    :placeholder="$t('name')" 
                    :rules="rules.required"
                    outlined 
                    dense 
                    hide-details="auto" 
                    >
                </v-text-field>
                <v-text-field 
                    class="mt-2" 
                    :label="$t('Email')" 
                    v-model="form.email"
                    :placeholder="$t('Email')" 
                    :rules="rules.required"
                    outlined 
                    dense 
                    hide-details="auto">
                </v-text-field>
                <v-select class="mt-2" 
                    :label="$t('gender')" 
                    :placeholder="$t('gender')" 
                    :rules="rules.required"
                    v-model="form.person_gender"
                    :items="genders"
                    item-value="value"
                    item-text="name"
                    outlined 
                    dense 
                    hide-details="auto">
                </v-select>
                <v-text-field 
                    class="mt-2" 
                    v-model="form.mobile"
                    :label="$t('phone')" 
                    :placeholder="$t('phone')" 
                    outlined 
                    dense 
                    hide-details="auto">
                </v-text-field>
                <v-select class="mt-2" 
                    :label="$t('Roles')" 
                    :placeholder="$t('Roles')" 
                    :rules="rules.required"
                    v-model="form.role"
                    :items="roles"
                    item-value="value"
                    item-text="name"
                    outlined 
                    dense 
                    hide-details="auto">
                </v-select>
                <v-text-field 
                    class="mt-2" 
                    type="password" 
                    v-model="form.password"
                    :label="$t('Password')" 
                    :placeholder="$t('Password')" 
                    :rules="rules.required"
                    outlined 
                    dense 
                    hide-details="auto">
                </v-text-field>
                <v-text-field 
                    class="mt-2" 
                    type="password" 
                    v-model="form.password_confirmation"
                    :label="$t('password_confirmation')" 
                    :placeholder="$t('password_confirmation')" 
                    :rules="rules.required"
                    outlined 
                    dense 
                    hide-details="auto">
                </v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text :loading="loading.submit" :disabled="!value" @click="submit">{{ $t('save') }}</v-btn>
            <v-btn text @click="close">{{ $t('close') }}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'UserDialog',
    props: ['id'],
    data() {
        return {
            display: true,
            value: false,
            loading: {
                data: false,
                submit: false
            },
            form: {
                id: null,
                role: null,
                username: null,
                email: null,
                mobile: null,
                password: null,
                password_confirmation: null,
                person_gender: null,
            },
            roles: [
                { name: this.$t('Super-Admin'), value: "Super-Admin" },
                { name: this.$t('Admin'), value: "Admin" },
                // { name: '', value: "" },
            ],
            genders: [
                { name: this.$t('Male'), value: "Male" },
                { name: this.$t('Female'), value: "Female" },
            ],
            rules: {
                required: [
                    v => !!v || this.$t('rules_required')
                ]
            }
        
        }
    },
    components: {
        
    },
    computed: {
        title() {
            return this.id == null ? this.$t('New User') : this.$t('Edit User');
        }
    },
    methods: {
        ...mapActions('Api', ["post", "getAll", "updata"]),
        ...mapActions('snackBar', ["setSnackBars"]),
        submit() {
            this.loading.submit = true;
            this.form.person_first_name = this.form.username;
            this.form.person_second_name = this.form.username;
            if(this.id == null) {
                this.save();
            }
        },
        save() {
            this.post({ url: `/users`, form: this.form}).then((res) => {
                this.loading.submit = false;
                 this.setSnackBars({color: "success", message: this.$t("record_save_success")});
                 this.$emit('save');
                console.log(res);
            }).catch((error) => {
                this.loading.submit = false;
                let message = error.response ? error.response.data.message : error.message;
                this.setSnackBars({color: "error", message: message});
            });
        },
        getUser() {
            this.loading.data = true;
            this.getAll(`users/${this.id}`).then((res) => {
                console.log('res', res);
                this.form = res.data.data;
                this.loading.data = false;
            }).catch((e) => {
                this.loading.data = false;
                console.log('tag', e);
            })
        },
        close() {
           this.$emit('close'); 
        }
    },
    created() {
        if(this.id != null) this.getUser();
    }
}
</script>

<style>

</style>