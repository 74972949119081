<template>
    <div>
        <v-card flat>
            <v-card-title>{{ $t('Users') }}</v-card-title>
            <v-card-text>
                <v-tabs>
                    <v-tab @click="clickTab('Super-Admin,Admin')">{{ $t('User System') }}</v-tab>
                    <v-tab @click="clickTab('User-App')">{{ $t('User App') }}</v-tab>
                    <!-- <v-tabs-items> -->
                        <v-tab-item>
                            <v-card flat>
                                <v-card-title>
                                <v-spacer></v-spacer>
                                 <v-tooltip v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            depressed
                                            class="ma-2 white--text"
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ $t('View User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-eye
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('View User') }}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="green"
                                            depressed
                                            class="ma-2 white--text"
                                            v-bind="attrs"
                                            @click="createUser"
                                            v-on="on">
                                            {{ $t('Add New User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Add New User') }}</span>
                                </v-tooltip>
                                <v-tooltip  v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="blue"
                                            class="ma-2 white--text"
                                            depressed
                                            v-bind="attrs"
                                            @click="editUser()"
                                            v-on="on">
                                            {{ $t('Update User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-pen
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Update User') }}</span>
                                </v-tooltip>
                                <v-tooltip  v-if="seleted.length" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="red"
                                            class="ma-2 white--text"
                                            depressed
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ $t('Delete User') }}
                                            <v-icon
                                                right
                                                dark>
                                                    mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('Delete User') }}</span>
                                </v-tooltip>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        v-model="seleted"
                                        show-select
                                        single-select
                                        :loading="loading"
                                        :headers="header"
                                        :items="users"
                                        dense>
                                         <template v-slot:item.active="{item}">
                                            <v-chip
                                                :color="userActive(item.active)"
                                                dark
                                                
                                            >
                                                {{ item.active == 1? 'Active' : 'Not Active' }}
                                            </v-chip>
                                           
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-title></v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        :loading="loading"
                                        :headers="header"
                                        :items="users"
                                        dense>
                                        <template v-slot:item.active="{item}">
                                           <v-chip
                                                :color="userActive(item.active)"
                                                dark
                                            >
                                                {{ item.active == 1? 'Active' : 'Not Active' }}
                                            </v-chip>
                                           
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
        <user-dialog :id="user.id" v-if="user.show" @save="onSave" @close="user.show = false"></user-dialog>
       
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import PermissionsDialog from '../../components/user/permissionsDialog.vue';
import UserDialog from '../../components/user/UserDialog.vue';
export default {
    name: 'Users',
    components: {
        UserDialog,
        // PermissionsDialog
        
    },
    data() {
        return {
            seleted: [],
            loading: false,
            tab : 'Admin',
            users: [],
            user: {
                show: false,
                id: null
            }
           
        }
    },
    computed: {
        header() {
            return [
                {text: this.$t('name'), value: 'username', },
                {text: this.$t('mobile'), value: 'mobile', },
                {text: this.$t('Email'), value: 'email', },
                {text: this.$t('type'), value: 'type', },
                {text: this.$t('Active'), value: 'active', }
            ]
        }
      
    },
    methods: {
        ...mapActions('Api', ["getAll"]),
        getUsers(v) {
            this.users  = [];
            this.loading = true;
           v = v === undefined ? '/users?filter[roles.name]=Super-Admin,Admin' : `/users?filter[roles.name]=${v}`
            this.getAll(`${v}`).then((res) => {
                console.log(res.data.data);
                this.users = res.data.data;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })

        },
        clickTab(v) {
            this.getUsers(v);
        },
        onSave() {
            this.getUsers('Super-Admin,Admin');
            this.user.show = false;
        },
        userActive(params) {
             if (params == 0) return 'red'
        else return 'green'
        },
        createUser() {
            this.user.show = true;
        },
        editUser() {
            this.user.id = this.seleted[0].id;
            this.user.show = true;
        }
       
    },
    created() {
        this.getUsers();
    }
}
</script>

<style>

</style>