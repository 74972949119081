var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Users')))]),_c('v-card-text',[_c('v-tabs',[_c('v-tab',{on:{"click":function($event){return _vm.clickTab('Super-Admin,Admin')}}},[_vm._v(_vm._s(_vm.$t('User System')))]),_c('v-tab',{on:{"click":function($event){return _vm.clickTab('User-App')}}},[_vm._v(_vm._s(_vm.$t('User App')))]),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('View User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,false,1353181436)},[_c('span',[_vm._v(_vm._s(_vm.$t('View User')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"green","depressed":""},on:{"click":_vm.createUser}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add New User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Add New User')))])]),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"blue","depressed":""},on:{"click":function($event){return _vm.editUser()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Update User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-pen ")])],1)]}}],null,false,663737022)},[_c('span',[_vm._v(_vm._s(_vm.$t('Update User')))])]):_vm._e(),(_vm.seleted.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"red","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Delete User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,false,2938237975)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete User')))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","single-select":"","loading":_vm.loading,"headers":_vm.header,"items":_vm.users,"dense":""},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.userActive(item.active),"dark":""}},[_vm._v(" "+_vm._s(item.active == 1? 'Active' : 'Not Active')+" ")])]}}]),model:{value:(_vm.seleted),callback:function ($$v) {_vm.seleted=$$v},expression:"seleted"}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title'),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.header,"items":_vm.users,"dense":""},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.userActive(item.active),"dark":""}},[_vm._v(" "+_vm._s(item.active == 1? 'Active' : 'Not Active')+" ")])]}}])})],1)],1)],1)],1)],1)],1),(_vm.user.show)?_c('user-dialog',{attrs:{"id":_vm.user.id},on:{"save":_vm.onSave,"close":function($event){_vm.user.show = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }