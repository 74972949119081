<template>
  <div>
    <div class="text-center" v-if="loading">
   
      <v-progress-circular color="primary" indeterminate> </v-progress-circular>
    </div>
    <v-card flat v-if="!loading">
      <v-card-text>
        <v-row>
          <v-col v-if="stats.users">
            <v-card color="#2596be">
              <v-card-text>
                <v-row dark>
                  <v-col cols="4">
                    <v-icon dark size="50"> mdi-account</v-icon>
                  </v-col>
                  <v-col cols="8" dark>
                    <p style="color: #fff; font-weight: bold">
                      {{ $t("Users") }} ( {{ stats.users }})
                    </p>
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="stats.policies">
            <v-card color="#76b5c5">
              <v-card-text>
                <v-row dark>
                  <v-col cols="4">
                    <v-icon dark size="50">
                      mdi-badge-account-horizontal</v-icon
                    >
                  </v-col>
                  <v-col cols="8" dark>
                    <p style="color: #fff; font-weight: bold">
                      {{ $t("Policy management") }} ({{ stats.policies }})
                    </p>
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="stats.companies">
            <v-card color="#063970">
              <v-card-text>
                <v-row dark>
                  <v-col cols="4">
                    <v-icon dark size="50"> mdi-bank</v-icon>
                  </v-col>
                  <v-col cols="8" dark>
                    <p style="color: #fff; font-weight: bold">
                      {{ $t("Insurance Company") }} ({{stats.companies}})
                    </p>
                   
                  </v-col>
                </v-row></v-card-text
              >
            </v-card>
          </v-col>
          <v-col v-if="stats.policiesTotalSales">
            <v-card color="#e28743">
              <v-card-text>
                <v-row dark>
                  <v-col cols="4">
                    <v-icon dark size="50"> mdi-sale</v-icon>
                  </v-col>
                  <v-col cols="8" dark>
                    <p style="color: #fff; font-weight: bold">
                      {{ $t("Sales") }} ({{ stats.policiesTotalSales }})
                    </p>
                   
                  </v-col>
                </v-row></v-card-text
              >
            </v-card>
          </v-col>
           <v-col v-if="stats.schemes">
            <v-card color="secondary">
              <v-card-text>
                <v-row dark>
                  <v-col cols="4">
                    <v-icon dark size="50"> mdi-notebook-outline</v-icon>
                  </v-col>
                  <v-col cols="8" dark>
                    <p style="color: #fff; font-weight: bold">
                      {{ $t("schemes") }} ({{ stats.schemes }})
                    </p>
                   
                  </v-col>
                </v-row></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="isAdmin">
          <v-col>
          <v-card flat max-width="500">
            <v-card-text>
              <area-chart  :data="dataChart" :options="{responsive: true, maintainAspectRatio: false}" :label="labels" :dataChartPerson="dataChartPerson"></area-chart>
            </v-card-text>
          </v-card>
          </v-col>
          <v-col> 
         
          </v-col>
        </v-row>
       
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AreaChart from '../components/Chart/AreaChart.vue';
// import PieChart from '../components/Chart/PieChart.vue';
import _ from 'lodash';
 // <pie-chart :companies="stats.companies"></pie-chart> 
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      policies: [],
      stats: {},
       message: "Hello World",
    dataChart: [],
    labels: [],
    dataChartPerson: [],
    company: []
    };
  },
  computed: {
   ...mapState(['user']),
   ...mapGetters(["isAdmin"]),
   
  },
  components: {
    AreaChart,
    // PieChart
    
  },
  methods: {
    ...mapActions("Api", ["getAll"]),
 changeData: function() {
      this.dataChartPerson = this.policies.map(e =>Number(e.personTotal.replace(/[^0-9.-]+/g,"")));
      this.labels = this.policies.map(e => e.startDate);
    },
    getStats() {
      try {
        this.loading = true;
        this.getAll(`/stats`)
          .then((res) => {
            this.loading = false;
            this.stats = res.data.data;
            console.log(res.data.data);
          })
          .catch(() => {
            this.loading = false;
          });
      } catch {
        console.log("error");
      }
    },
    getAllPolicies() {
try {
            //  this.loading = true;
        this.getAll(`policies`)
          .then((res) => {
            console.log(res.data.data);
            this.policies = res.data.data;
            console.log('tag', this.policies.map(e => Number(e.personTotal.replace(/[^0-9.-]+/g,""))));
            this.dataChart = this.policies.map(e => Number(e.total.replace(/[^0-9.-]+/g,"")));
            this.dataChartPerson = this.policies.map(e => Number(e.personTotal.replace(/[^0-9.-]+/g,"")));
            this.company = this.policies.map((e) => {
              if(e.companyName == e.companyName) return _.sum(e);
            });
            console.log('tag',  this.company);
            this.labels = this.policies.map(e => e.startDate);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }
    },
   
  },
  mounted() {
    this.getStats();
    this.getAllPolicies();
    
  },
};
</script>
